<template>
  <div class="pages p20">
    <div class="title-btn-block">
      <div class="header-filter">
        <div class="title-block">Tabel</div>
        <div class="mx-3">
          <el-date-picker
            v-model="filterForm.from_date"
            type="date"
            placeholder="От"
            size="small"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
          >
          </el-date-picker>
        </div>
        <div class="mx-3">
          <el-date-picker
            v-model="filterForm.to_date"
            type="date"
            placeholder="До"
            size="small"
            :format="'dd.MM.yyyy'"
            :value-format="'dd.MM.yyyy'"
          >
          </el-date-picker>
        </div>
      </div>
      <div>
        <router-link :to="{ name: 'Home' }">
          <div class="my-btn cr1 el-button">
            <i class="el-icon-d-arrow-left"></i> Orqaga
          </div>
        </router-link>
      </div>
    </div>

    <div class="content-block">
      <div class="datetime-end bg p20 mb20">
        <el-divider content-position="left">
          Xodimning ishga kelgan va kelmaganligi haqida ma’lumot
        </el-divider>

        <div class="bg-dark my-card">
          <div class="card-block-itme">
            Bugungi ish soatlari: {{ today ? today.total_working_hour : "0" }}
          </div>
          <div class="card-block-itme">
            Jami ish soatlari:
            {{ monthly.length ? monthly[0].total_working_hour : "0" }}
          </div>
          <div class="card-block-itme">
            Jami ish kunlari:
            {{ monthly.length ? monthly[0].total_working_day : "0" }}
          </div>
        </div>

        <div class="scrol-table crmhome__scroll my-table">
          <table class="table-my-code table-bordered" v-loading="loadingData">
            <thead>
              <tr>
                <th rowspan="2">Sana va vaqt</th>
                <!-- <th rowspan="2">Часы</th> -->
                <th>Vaqt</th>
                <th>Jami ish soatlari</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="day in daily" :key="day.id">
                <td>{{ day.date }}</td>
                <td>
                  {{ day.check_in ? day.check_in.slice(-8) : "" }} -
                  {{ day.check_out ? day.check_out.slice(-8) : "" }}
                </td>
                <td>{{ day.total_working_hour }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getItem } from "@/utils/storage";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      monthly: [],
      total_working_hour: null,
      total_working_day: null,
      daily: [],
      today: [],
      filterForm: {},
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters({
      list: "assent/list",
    }),
  },
  mounted() {
    this.table();
  },
  methods: {
    ...mapActions({
      updateList: "attendanceReport/index",
    }),
    checkMonth() {},
    table() {
      const query = { id: getItem("userId"), ...this.filterForm };
      this.updateList(query).then((res) => {
        this.daily = res.dailyReports;
        this.monthly = res.monthlyReports;
        this.today = res.todayReport;
      });
    },
  },
  watch: {
    filterForm: {
      handler: async function (newVal, oldVal) {
        const query = { id: getItem("userId"), ...this.filterForm  };
        await this.updateList(query).then((res) => {
          this.daily = res.dailyReports;
          this.monthly = res.monthlyReports;
          this.today = res.todayReport;
        });
        // this.debouncedFetchData();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style>
.my-card {
  display: flex;
  justify-content: space-between;
}
.header-filter{
  display: flex;
  gap: 15px;
}
.my-card .card-block-itme {
  background: #161d31;
  width: 32%;
  max-width: 32%;
  border-radius: 10px;
  min-height: 100px;
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
}
</style>
